import React from "react"
import Layout from "../components/layout"
import { injectIntl } from "gatsby-plugin-intl"

const Supported = ({ intl }) => (
  <Layout>
    
    <div className="main">
      <div className="block standard not-found">
        <div className="container-fluid">
          <div className="row">
            <div className="col col-xl-6">
              <h1>{intl.formatMessage({ id: "Browser not supported" })}</h1>
              <p>{intl.formatMessage({ id: "Unfortunately your browser is not supported. Please upgrade to a modern browser to view The Global Payments Report online experience. You can still access the report or get in contact with us using the buttons on this page." })}</p>
              
            </div>
          </div>
        </div>
      </div>
    </div>   
    
  </Layout>
)

export default injectIntl(Supported)
